<!-- @Author: Yu_Bo -->
<template>
  <div>
    <div class='book_url zc_dialog_box'>
      <el-dialog :visible.sync="dialogVisible" width="640px" :close-on-click-modal='false'>
        <div class="title" slot="title">
          <div class="name">链接转台本</div>
        </div>
        <div class="url_main">
          <div class="main_text">
            <el-input type="textarea" v-model="textarea" :rows="6" resize='none' placeholder="请输入视频分享链接" > </el-input>
          </div>
          <div class="main_tip">注：请输入抖音、火山、快手、西瓜、小红书等视频链接；</div>
          <div class="main_btn">
            <div class="btn_left">
              <el-radio v-model="radio" :label="1">提取文本</el-radio>
              <el-radio v-model="radio" :label="2">提取声音</el-radio>
            </div>
            <div class="btn_right">
              <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
              <el-button class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn">确 定</el-button>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 加载中 -->
    <book-loading ref="loadingBook" type='video_link' @cancel='cancelLoadingBtn'></book-loading>
    <!--  -->
    <point-out ref='dialogTip' dialogTitle="提示" dialogWidth='380px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='false' :affirmShow='true'>
      <div class="zc_dialog_tip_content">
        <div class="text">视频地址获取失败，请稍等重试。</div>
      </div>
    </point-out>
  </div>
</template>

<script>
  import BookLoading from '@/components/book_page/book_loading.vue'//加载
  import PointOut from '@/components/point_out/point_out.vue'//提示
	export default {
		components: {
      BookLoading,
      PointOut
    },
		props: {},
		data() {
			return{
        dialogVisible:false,
        textarea:'',
        radio:null,
        typeShow:false,
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开
      openDialog() {
        this.typeShow=false
        this.dialogVisible = true
      },
      // 取消
      cancelBtn(){
        this.textarea=''
        this.radio=null
        this.dialogVisible = false
      },
      // 确定
      affirmBtn(){
        var that = this
        if(that.textarea==''){
          that.$warMsg('请输入视频分享链接')
        }else if(!that.radio){
          that.$warMsg('请选择转换类型')
        }else{
          that.$refs.loadingBook.openDialog()
          var parasm = {
            type:that.radio,
            url:that.textarea,
          }
          that.$workbenApi.shareUrl(parasm).then(res => {
            if (res.code == 1000) {
              if(that.typeShow){
                // 不跳转
                that.typeShow=false
                return
              }else{
                // 跳转
                that.$refs.loadingBook.closeBtn()
                var info = {
                  type:that.radio,
                  val:that.radio==1?res.result.texts:res.result.sound
                }
                that.$store.commit('SET_BOOK',info)
                that.$router.push('/workben/drama/-3')
                that.cancelBtn()
              }
            } else {
              that.$refs.dialogTip.openDialogBtn()
              // that.$errMsg(res.message)
            }
          })
        }
      },
      // 取消
      cancelLoadingBtn(val){
        this.typeShow=true
      }
    },
	}
</script>

<style lang='scss' scoped>
	.book_url{
    .url_main{
      width: 100%;
      .main_text{
        width: 100%;
      }
      .main_tip{
        width: 100%;
        padding-top: 10px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .main_btn{
        width: 100%;
        padding-top: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn_left{
          display: flex;
          align-items: center;
        }
        .btn_right{
          display: flex;
          align-items: center;
        }
      }
    }
	}
</style>
